.item{
  &-banner{
    position: relative;
    margin-bottom: 120px;
    &-container{
      display: flex;
      height: calc(100vh - 84px);
      min-height: 600px;
      width: 100%;
    }
    &-desc{
      display: flex;
      flex-direction: column;
      max-width: calc(100% / 12 * 5 - 20px);
      &__title{
        font-weight: bold;
        font-size: 48px;
        line-height: 125%;
        color: var(--black);
        margin-bottom: 64px;
      }
      .breadcrumbs{
        margin-bottom: auto;
      }
      &-block{
        &:not(:last-child){
          margin-bottom: 24px;
        }
        &__title{
          font-size: 16px;
          line-height: 18px;
          color: var(--purple);
          display: flex;
          margin-bottom: 16px;
          align-items: center;
          svg{
            margin-right: 8px;
            /*margin-top: 2px;*/
          }
        }
        &__text{
          font-size: 16px;
          line-height: 150%;
          color: var(--darkGray);
        }
      }
      &-bot{
        display: flex;
        margin-bottom: auto;
        margin-top: 64px;
        &__btn{
          &:last-child{
            margin-left: 24px;
            border: none;
            box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
            font-weight: normal;
            color: var(--darkGray);
          }
        }
      }
    }
    &-map{
      #item-map{
        position: absolute;
        left: calc(100% / 12 * 5 + 40px);
        bottom: 0;
        top: 0;
        right: 0;
      }
    }
  }
  &-about{
    margin-top: 120px;
    margin-bottom: 120px;
    
    &-container{
      display: flex;
      justify-content: space-between;
    }
    &-slider{
      width: calc(50% - 20px);
      .about-full-aside-galery__img{
        height: 550px;
        width: 100%;
      }
    }
    &-desc{
      padding-left: 5%;
      width: calc(50% - 20px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-items{
        display: inline-flex;
        flex-direction: column;
      }
      &__text{
        max-width: 420px;
        font-size: 16px;
        line-height: 150%;
        color: var(--darkGray);
        margin-bottom: 16px;
      }
      &__item{
        background: var(--white);
        border-radius: 3px;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 46px;
        font-size: 16px;
        line-height: 150%;
        color: var(--darkGray);
        &:not(:last-child){
          margin-bottom: 16px;
        }
        svg{
          margin-right: 16px;
        }
      }
      &__btn{
        margin-top: 40px;
      }
    }
  }
  &-facts{
    margin-top: 120px;
    margin-bottom: 120px;
    &-row{
      display: flex;
      margin: -40px;
      width: calc(100% + 80px);
    }
    &-item{
      width: calc(100% / 3 - 80px);
      margin: 40px;
      position: relative;
      &__text{
        position: absolute;
        bottom: 24px;
        left: 50%;
        padding: 9px 24px;
        display: block;
        background-color: var(--white);
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: var(--black);
        transform: translateX(-50%);
      }
      &-imgbox{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: center;
        transition: .3s;
        transition-timing-function: ease-in-out;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &-outer{
          &:hover{
            .item-facts-item-imgbox{
              transform: scale(1.2);
            }
          }
          overflow: hidden;
          width: 100%;
          padding-bottom: 100%;
          position: relative;
        }
      }
    }
  }
  &-similar{
    margin-bottom: 150px;
    margin-top: 120px;
    .filter{
      margin-bottom: 30px;
    }
    &-slider{
      
      position: relative;
      .owl-stage-outer{
        overflow: hidden;
        padding: 10px 0;
      }
      .owl-nav{
        width: calc(100% + 59px + 59px);
        left: -59px;
      }
    }
    .areas-card{
      width: auto;
      margin: 0;
    }
  }
}


@media screen and (max-width: 1023px){
  .item{
    &-banner{
      position: relative;
      margin-bottom: 80px;
      &-desc{
        flex: none;
        max-width: 100%;
        &-bot{
          margin-top: 40px;
        }
        &__title{
          font-size: 36px;
          margin-bottom: 40px;
          padding-top: 24px;
          br{
            display: none;
          }
        }
        &-block{
          &-outer{
            display: flex;
          }
          &:nth-child(1){
            padding-right: 20px;
          }
          &:not(:last-child){
            margin-bottom: 0;
          }
        }
        height: auto;
        order: 2;
        .breadcrumbs{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          padding-left: 40px;
          z-index: 4;
          &:after{
            content: '';
            display: block;
            width: 100%;
            height: 50px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
          }
        }
      }
      &-container{
        flex-direction: column;
        height: auto;
        min-height: 0;
      }
      &-map #item-map{
        position: relative;
        top: 0;
        left: 0;
        width: calc(100% + 80px);
        height: 400px;
        margin-left: -40px;
      }
    }
    &-about{
      margin-top: 80px;
      margin-bottom: 80px;
      &-slider{
        order: 2;
        flex: 0 0 420px;
        align-self: center;
        .about-full-aside-galery__img{
          height: 360px;
        }
      } 
      &-desc{
        padding-right: 24px;
        padding-left: 0;
        flex: 0 0 calc(100% - 420px);
        &-items{
          width: 100%;
        }
        &__item{
          padding-right: 0;
          width: 100%;
        }
      }
    }
    &-facts{
      margin-top: 80px;
      margin-bottom: 60px;
      &-row{
        margin: -20px;
        width: calc(100% + 40px);
      }
      &-item{
        margin: 20px;
        width: calc(100% / 3 - 40px);
        &__text{
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }
    &-similar{
      margin-top: 60px;
      margin-bottom: 80px;
      .filter{
        margin-bottom: 40px;
      }
      &-slider{
        display: flex;
        margin: 0 -16px;
        width: calc(100% + 32px);
        .areas-card{
          width: calc(50% - 32px);
          margin: 0 16px;
          &:nth-child(n+3){
            display: none;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 767px){
  .item{
    &-banner{
      &-map #item-map{
        height: 320px;
        margin-left: -16px;
        width: calc(100% + 32px);
      }
      &-desc{ 
        .breadcrumbs{
          padding-left: 16px;
        }
        &-block{
          &-outer{
            display: block;
          }
          &__title{
            margin-bottom: 8px;
          }
          &:not(:last-child){
            padding-right: 0;
            margin-bottom: 16px;
          }
        }
        &__title{
          font-size: 24px;
          margin-bottom: 24px;
        }
        &-bot{
          margin-top: 24px;
          &__btn{
            width: 100%;
            &:last-child{
              display: none;
            }
          }
        }
      }
    }
    &-about{
      &-slider{
        order: 2;
        width: 100%;
        flex: none;
        .about-full-aside-galery{
          margin-bottom: 0;
          &__img{
            height: 280px;
          }
        }
      }
      &-desc{
        padding-right: 0;
        width: 100%;
        flex: none;
        &__btn{
          margin-bottom: 16px;
          margin-top: 16px;
          width: 100%;
        }
      }
      &-container{
        flex-direction: column;

      }
    }
    &-facts{
      &-row{
        margin: 0;
        width: 100%;
        display: block;
      }
      &-item{
        margin: 0;
        width: 100%;
        &:not(:last-child){
          margin-bottom: 24px;
        }
      }
    }
    &-similar{
      &-slider{
        display: block;
        width: 100%;
        margin: 0;
        .areas-card{
          width: 100%;
          margin: 0;
          &:not(:last-child){
            margin-bottom: 40px
          }
        }
      }
    }
  }
}