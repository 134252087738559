.footer{
  background: #FCFCFC;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px 3px 0 0;
  &-top{
    padding: 36px 0;
    display: flex;
    align-items: center;
  }
  &__logo, &__rights, &__rastudio, &-social{
    flex: 1;
  }

  &-linklist{
    flex: 3;
  }
  &__agreement{
    flex: 2;
  }

  
  &-linklist{
    display: flex;
    align-items: center;
    &__link{
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: var(--black);
      transition: .3s;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      &:not(:last-child){
        margin-right: 32px;
      }
      &:hover{
        @media screen and (min-width: 1024px) {
          color: var(--purple);
        }
      }
    }
  }
  &-social{
    display: flex;
    justify-content: flex-end;
    &__link{
      &:not(:last-child){
        margin-right: 20px;
      }
    }
  }
  &__rights, &__agreement {
    font-size: 16px;
    line-height: 18px;
    color: var(--middleGray);
  }
  &__agreement{
    transition: .3s;
    &:hover{
      @media screen and (min-width: 1024px) {
        color: var(--purple);
      }
    }
  }
  &__rastudio{
    display: flex;
    svg{
      margin-left: auto;
    }
  }
  &-bot{
    border-top: 1px solid var(--lightGray);
    padding: 32px 0 24px;
    display: flex;
    align-items: center;
  }
}


@media screen and (max-width: 1200px){
  .footer-linklist__link{
    white-space: nowrap;
    &:not(:last-child){
      margin-right: 16px;
    }
  }
}


@media screen and (max-width: 1023px){
  .footer-top{
    flex-wrap: wrap;

  }
  .footer-linklist{
    order: 2;
    justify-content: space-between;
    margin-top: 24px;
  }
  .footer-bot{
    justify-content: space-between;
  }
  .footer__rights, .footer__agreement, .footer__rastudio{
    flex: none;
  }
}


@media screen and (max-width: 767px){
  .footer{
    &-top{
      padding: 24px 0 40px;
    }
    &-linklist{
      width: 100%;
      flex: none;
      flex-direction: column;
      margin-top: 40px;
      align-items: flex-start;
      &__link{

        &:not(:last-child){
          margin-bottom: 24px;
        }
      }
    }
    &-bot{
      flex-direction: column;
      align-items: flex-start;
      padding-top: 40px;
    }
    &__rights, &__agreement{
      margin-bottom: 24px;
    }
  }
}