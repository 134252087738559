.contacts{
  position: relative;
  margin-bottom: 0 !important;
  &-row{
    display: flex;
  }
  &-desc{
    width: 50%;
    padding-right: 20px;
    .defaultTitle{
      margin-bottom: 0;
    }
    &-block{
      padding: 40px 0;
      margin: -12px;
      width: calc(100% + 24px);
      &__title{
        font-size: 16px;
        line-height: 150%;
        color: var(--darkGray);
        margin-bottom: 8px;
      }
      &__text{
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--black);
      }
      &-item{
        margin: 12px;
      }
      &:not(:last-child){
        border-bottom: 1px solid var(--lightGray);
      }
      &:last-child{
        display: flex;
        flex-wrap: wrap;
        .contacts-desc-block-item{
          width: calc(50% - 24px);
        }
      }
    }
  }
  &-map{
    #contacts-map{
      position: absolute;
      left: calc(50% + 20px);
      right: 0;
      bottom: 0;
      top: 0;
    }
  }
}


@media screen and (max-width: 1023px){
  .contacts{
    &-row{
      flex-direction: column;
    }
    &-map{
      width: calc(100% + 80px);
      margin-left: -40px;
      #contacts-map{
        position: relative;
        top: 0;
        left: 0;
        height: 400px;
      }
    }
    &-desc{
      width: 100%;
      order: 2;
      padding-top: 40px;
      margin-bottom: 80px;
      padding-right: 0;
      &-block:not(:last-child){
        display: flex;
        flex-wrap: wrap;
        .contacts-desc-block-item{
          width: calc(50% - 24px);
          &:last-child{
            width: calc(100% - 24px);
          }
        }
      }
      .breadcrumbs{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-left: 40px;
        z-index: 4;
        &:after{
          content: '';
          display: block;
          width: 100%;
          height: 50px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }
  }
}


@media screen and (max-width: 767px){
  .contacts{
    &-map{
      margin-left: -16px;
      width: calc(100% + 32px);
      #contacts-map{
        height: 240px;
      }
    }
    &-desc{
      .breadcrumbs{
        padding-left: 16px;
      }
      &-block{
        padding: 20px 0;
        display: block !important;
        width: 100% !important;
        margin: 0 !important;
        .contacts-desc-block-item{
          width: 100% !important;
          margin: 0 !important;
          &:not(:last-child){
            margin-bottom: 24px !important;
          }
        }
      }
    } 
  }
}