.about-full{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 350px auto;
  &-content{
    padding-right: 20px;
    grid-row-start: 1; 
    grid-row-end: 3; 
    p{
      display: block;
      max-width: 530px;
      font-size: 16px;
      line-height: 150%;
      color: #515151;
      &:not(:last-child){
        margin-bottom: 24px;
      }
    }
  }
  &-aside{
    &-galery{
      overflow: hidden;
      border-radius: 4px;
      position: relative;
      .owl-stage{
        display: flex;
      }
      .owl-dots{
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        bottom: 12px;
        .owl-dot {
          cursor: pointer;
          padding: 12px;
          background: none;
          border: none;
          span{
            border-radius: 50%;
            display: block;
            background: #FFFFFF;
            border: 1px solid #B3B3B3;
            width: 8px;
            height: 8px;
          }
          &.active{
            span{
              background: #242C95;
              border-color: #242C95;
              box-shadow: 1px 2px 10px rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
      &__img{
        width: 100%;
        height: 350px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .about-num{
    margin-left: 0;
    &-item{
      margin-bottom: 0 !important;
      margin-top: 80px;
    }
  }
}


@media screen and (max-width: 1023px){
  .about-full{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-aside-galery{
      width: 100%;
      margin-bottom: 72px;
    }
    &-content{
      order: 2;
      width: 100%;
      padding-right: 0;
      p{
        max-width: none;
      }
    }
    .about-num{
      order: 3;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin: 28px -12px -12px;
      width: calc(100% + 24px);
      &-item{
        width: calc(25% - 24px);
        margin: 12px;
      }
    }
  }
}


@media screen and (max-width: 767px){
  .about-full{
    .about-full-aside-galery{
      margin-bottom: 24px;
      &__img{
        height: 180px;
      }
    }
    .about-num{
      margin: 20px -16px -20px;
      width: calc(100% + 32px);
      &-item{
        width: calc(50% - 32px);
        margin: 20px 16px !important;
      }
    }
  }
  
}