:root{
  --blue: #242C95;
  --purple: #6F78E5;
  --lightBlue: #E7E8FF;
  --black: #1B1B1B;
  --darkGray: #6B6B6B;
  --lightGray: #BCBCBC;
  --white: #FEFEFE;
  --middleGray: #989898;
  --superLightGray: #EFEFEF;
}
.input-err{
  border-color: #E56F6F !important;
}
.input-border{
  border-color: var(--darkGray) !important;
}

table {
  border-collapse: collapse;
  td{
    padding: 8px 24px;
    border: 1px solid #000;
    a{
      font-size: 18px;
    }
  }
}

.body-overflow{
  overflow: hidden;
  max-height: 100vh;
}

.body-compensate{
  @media screen and (min-width: 1024px){
    //**** Compensate scroll-bar ****//
    padding-right: 17px;
  }
}

body{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer{
    margin-top: auto;
  }
}

.wrapper{
  //overflow: hidden;
}
.firstSolo{
  margin-top: 84px !important;
  margin-bottom: 120px !important;
}
.owlStyle{
  
  .owl-stage{
    display: flex;
  }
  .owl-dots{
    display: none;
  }
  .owl-nav{
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;
    transform: translateY(-50%);
    .owl-prev, .owl-next{
      pointer-events: all;
      span{
        display: none;
      }
      cursor: pointer;
      border: none;
      background: none;
      padding: 24px;
      filter: drop-shadow(1px 2px 10px rgba(0, 0, 0, 0.4));
    }
  }
}

.breadcrumbs{
  padding-top: 24px;
  margin-bottom: 40px;
  display: flex;
  align-items: baseline;
  &__link{
    display: inline-flex;
    align-items: baseline;
    font-size: 14px;
    line-height: 120%;
    color: var(--middleGray);
    
    svg{
      margin: 0 8px;
    }
    &:first-child{
      color: var(--blue);
      svg{
        margin-left: 0;
      }
    }
  }
}

.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  svg path{
    transition: .3s;
  }
  &__miss{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--middleGray);
    justify-content: center;
  }
  &__arr{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    transition: .3s;
    margin: 0 4px;
    line-height: 19px;
    color: var(--middleGray);
    background-color: var(--white);
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    &:not(.pagination__arr_disabled):not(.pagination__arr_page_ACTIVE):hover{
      @media screen and (min-width: 1024px) {
        background-color: var(--purple);
        color: var(--white);
        svg path{
          stroke: var(--white);
        }
      }
    }
    &_prev{
      margin-right: 20px;
    } 
    &_next{
      margin-left: 20px;
    }
    &_page{
      &_ACTIVE{
        background-color: var(--blue);
        color: var(--white);
        border-radius: 4px;
      }
    }
    &_disabled{
      svg{
        path{
          stroke: var(--superLightGray);
        }
      }
    }
  }
}

.container{
  max-width: 1400px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;
}

.btn{
  background-color: var(--blue);
  border: 1.5px solid var(--blue);
  color: #FFFFFF;
  height: 40px;
  padding-right: 24px;
  padding-left: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: 500;
  font-size: 16px;
  transition: .3s;
  line-height: 125%;
  cursor: pointer;
  &:hover{
    @media screen and (min-width: 1024px) {
      background-color: var(--purple);
      border-color: var(--purple);
    }
  }
  &:active{
    @media screen and (min-width: 1024px) {
      
      background-color: var(--blue);
      border-color: var(--blue);
    }
  }
  svg{
    margin-right: 8px;
  }
  &_p16{
    padding-right: 16px;
    padding-left: 16px;
  }
  &_h50{
    height: 50px;
  }
  &_white{
    background-color: var(--white);
    color: var(--blue);
    &:hover{
      @media screen and (min-width: 1024px) {
        background-color: var(--lightBlue);
        border-color: var(--blue);
      }
    }
    &:active{
      @media screen and (min-width: 1024px) {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
    &_light{
      border-width: 1px;
      font-weight: normal;
    }
    &_lightBlue{
      border-width: 1px;
      background-color: var(--white);
      color: var(--purple);
      font-weight: normal;
      border-color: var(--purple);
      &:hover{
        @media screen and (min-width: 1024px) {
          background-color: var(--lightBlue);
          
        }
      }
      &:active{
        @media screen and (min-width: 1024px) {
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

.filter{
  display: flex;
  margin-bottom: 40px;
  &-left{
    display: flex;
  }
  &-label{
    &:not(:last-child){
      margin-right: 16px;
    }
    input{
      display: none;
      &:checked + .filter-button{
        background: var(--purple);
        color: var(--white);
      }
    }
  }
  &-right{
    display: flex;
    margin-left: auto;
    .filter__link_sphere{
      display: none;
    }
    .filter__link_map{
      &:after{
        content: 'Показать на карте';
      }
    }
    .filter__link_list{
      &:after{
        content: 'Показать списком';
      }
    }
  }
  &-button{
    color: var(--middleGray);
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: var(--white);
    font-size: 16px;
    line-height: 18px;
    padding: 11px 16px;
    transition: .3s;
    cursor: pointer;
    &_where{
      &:after{
        content: 'По местоположению';
      }
    }
    &:hover{
      @media screen and (min-width: 1024px) {
        color: var(--purple);
      }
    }
  }
  &__link{
    &:not(:last-child){
      margin-right: 24px;
    }
  }
}

.default{
  &Sup{
    padding: 6px 16px;
    color: var(--darkGray);
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 18px;
    background: var(--lightBlue);
    border-radius: 3px;
    display: inline-block;
  }
  &Title{
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    color: var(--black);
    margin-bottom: 40px;
  }
}

.areas{
  &-grid{
    display: flex;
    margin: -20px;
    flex-wrap: wrap;
    width: calc(100% + 40px);
    margin-bottom: 60px;
  }
  &-card{
    width: calc(100% / 3 - 40px);
    margin: 20px;
    &:hover{
      @media screen and (min-width: 1024px) {
        .areas-card-desc{
          box-shadow: 1px 2px 10px rgba(111, 120, 229, 0.35);
        }
      }
    }
    &-map{
      width: 100%;
      height: 240px;
      position: relative;
      border-radius: 3px;
      overflow: hidden;
      &:hover{
        @media screen and (min-width: 1024px){
          .areas-card-map__img_hover{
            opacity: 1;
          }
        }
      }
      &__img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        &_main{
          z-index: 2;
        }
        &_hover{
          z-index: 3;
          transition: .3s;
          opacity: 0;
        }
      }
    }
    &-desc{
      margin: -40px 16px 0;
      position: relative;
      transition: .3s;
      z-index: 4;
      background: var(--white);
      box-shadow: 1px 2px 10px rgba(111, 120, 229, 0.1);
      border-radius: 3px;
      pointer-events: all;
      &_baloon{
        margin: 0;
        width: 400px;
        /*.areas-card-desc-top,
        .areas-card-desc-main{
          border-bottom: none;
        }*/
        .areas-card-desc-main-top__fav{
          cursor: pointer;
        }
      }
      &-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid var(--superLightGray);
        &__status{
          font-size: 14px;
          line-height: 150%;
          text-align: right;
          color: var(--middleGray)
        }
      }
      &-main{
        padding: 16px;
        border-bottom: 1px solid var(--superLightGray);
        &-top{
          display: flex;
          align-items: flex-start;
          margin-bottom: 16px;
          &__title{
            font-weight: 500;
            font-size: 24px;
            line-height: 125%;
            color: var(--black);
          }
          &__fav{
            margin-left: auto;
            margin-top: 4px;
            flex-shrink: 0;
            &_active{
              path{
                fill: var(--purple);
                fill-opacity: 1;
              }
            }
            path{
              transition: .3s;
            }
            &:hover path{
              @media screen and (min-width: 1024px) {
                fill: var(--purple);
                fill-opacity: 0.2;
              }
            }
          }
        }
        &__item{
          display: flex;
          align-items: flex-start;
          font-size: 16px;
          line-height: 150%;
          color: var(--darkGray);
          &:not(:last-child){
            margin-bottom: 16px;
          }
          svg{
            margin-right: 8px;
            margin-top: 4px;
            flex-shrink: 0;
          }
        }
      }
      &__where{
        padding: 16px;
        display: flex;
        align-items: flex-start;
        font-size: 16px;
        line-height: 150%;
        color: var(--darkGray);
        svg{
          margin-top: 4px;
          margin-right: 8px;
          flex-shrink: 0;
        }
      }
    }
  }
}


.form{
  margin-top: 120px;
  margin-bottom: 128px;
  overflow: hidden;
  &-row{
    display: flex;
    margin-top: 64px;
  }
  &-col{
    flex: 1 1 535px;
    .form-check{
      &__title{
        font-size: 16px;
        line-height: 18px;
        color: var(--darkGray);
        margin-bottom: 16px;
      }
      &-row{
        display: flex;
        flex-wrap: wrap;
        margin: -8px;
        width: calc(100% + 16px);
      }
      &-button{
        color: var(--middleGray);
        font-size: 16px;
        line-height: 18px;
        border: 1px solid var(--lightGray);
        border-radius: 3px;
        padding: 10px 16px;
        cursor: pointer;
        transition: .3s;
        &:hover{
          @media screen and (min-width: 1024px) {
            border-color: var(--purple);
            color: var(--purple);
          }
        }
      }
      &-label{
        margin: 8px;
        input{
          display: none;
          &:checked + .form-check-button{
            background: var(--purple);
            border-color: var(--purple);
            color: var(--white);
          }
        }
      }
    }
    .form-inputs{
      &-row{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .form-input{
        position: relative;
        margin-top: 56px;
        width: 100%;
        &_half{
          width: calc(50% - 20px);
        }
        input{
          padding: 8px 0;
          border: none;
          width: 100%;
          font-size: 16px;
          line-height: 18px;
          transition: .3s;
          border-bottom: 1.5px solid var(--lightGray);
          &::placeholder{
            font-size: 0;
          }
          &:not(:placeholder-shown) + label{
            transform: none;
            bottom: 36px;
            font-size: 12px;
            line-height: 14px;
          }
          &:focus + label{
            transform: none;
            bottom: 36px;
            font-size: 12px;
            line-height: 14px;
          }

          &:hover{
            @media screen and (min-width: 1024px) {
              border-color: var(--purple);
            }
          }
          &:focus{
            border-color: var(--purple);
          }
        }
        label{
          position: absolute;
          bottom: 50%;
          transform: translateY(50%);
          left: 0;
          z-index: 2;
          font-size: 16px;
          transition: .3s;
          line-height: 18px;
          color: var(--darkGray)
        }
      }
    }
    .form-bottom{
      margin-top: 64px;
      display: flex;
      align-items: center;
      &__text{
        font-size: 12px;
        line-height: 14px;
        color: var(--darkGray);
        a{
          color: var(--purple)
        }
      }
      .btn{
        margin-right: 20px;
        flex-shrink: 0;
      }
    }

  }
  
  &-img{
    flex: 1 1 calc(100% - 535px);
    align-self: center;
    padding-left: 50px;
    img{
      width: 120%;
      max-width: none;
    }
  }
}

.popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  &-content{
    background-color: #fff;
    box-shadow: 1px 2px 10px rgba(111, 120, 229, 0.1);
    border-radius: 3px;
    padding: 40px;
    max-width: calc(100% - 20px);
    &__close{
      cursor: pointer;
    }
    &-head{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title{
      font-weight: 500;
      font-size: 24px;
      line-height: 125%;
    }
    .form-col{
      max-width: 530px;
    }
  }

  &-filter{
    max-width: 584px;
    width: calc(100% + 40px);
    display: flex;
    flex-wrap: wrap;
    margin: 20px -20px -20px;
    &-input{
      margin: 20px;
      width: calc(50% - 40px);
      &_sphere{
        display: none;
      }
      &_square{
        .popup-filter-input-box{
          border: 1px solid var(--lightGray);
          border-radius: 3px;
        }
        .popup-filter-input-selector{
          width: 60px;
          border: none;
          border-radius: 0 3px 3px 0;
          border-left: 1px solid var(--lightGray);
          position: static;
        }
      }
      &__text{
        font-size: 16px;
        line-height: 18px;
        color: var(--darkGray);
        margin-bottom: 8px;
      }
      &-box{
        width: 100%;
        height: 40px;
        position: relative;
        display: flex;
        & > input{
          border: none;
          padding-left: 8px;
          flex: 1;
          min-width: 0;
          display: block;
          -moz-appearance:textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button{
            -webkit-appearance: none;
          }
        }
        &__text{
          padding-left: 16px;
          align-self: center;
          color: var(--darkGray);
        }
      }
      &-selector{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid var(--lightGray);
        border-radius: 3px;
        transition: .3s;
        &_notEmpty{
          .popup-filter-input-selector-view span{
            color: var(--black);
          }
        }
        &_active{
          z-index: 10;
          box-shadow: 1px 2px 10px rgba(111, 120, 229, 0.1);
          border-color: transparent;
          .popup-filter-input-selector-options{
            display: block;
            border-top: 1px solid var(--superLightGray);
          }
        }
        &-view{
          cursor: pointer;
          padding: 0 16px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: .3s;
          color: var(--darkGray);
          span{
            font-size: 16px;
            line-height: 18px;
            color: var(--darkGray);
          }
          &:hover{
            @media screen and (min-width: 1024px) {
              box-shadow: 1px 2px 10px rgba(111, 120, 229, 0.1);
            }
          }
        }
        &-options{
          display: none;
          background-color: #fff;
          border-radius: 0 0 3px 3px;
          width: 100%;
          padding: 8px 0;
          &__item{
            padding: 8px 16px;
            font-size: 16px;
            line-height: 18px;
            cursor: pointer;
            color: var(--lightGray);
            transition: .3s;
            &:hover{
              @media screen and (min-width: 1024px) {
                background-color: var(--lightBlue);
                color: var(--purple);
              }
            }
            &_choosen{
              color: var(--black);
              /*font-weight: 700;*/
            }
          }
        }
      }
    }
    &-bot{
      margin: 20px;
      width: calc(100% + 40px);
      display: flex;
      align-items: center;
      &__var{
        margin-right: auto;
        font-size: 16px;
        line-height: 125%;
        color: var(--black);
      }
      &__btn{
        &:not(:last-child){
          margin-right: 24px;
        }
        &_cancel{
          border: 1px solid #E56F6F;
          color: #E56F6F;
          &:hover{
            @media screen and (min-width: 1024px){
              border-color: #E56F6F;
              color: #E56F6F;
              background-color: lighten($color: #E56F6F, $amount: 30);
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1460px){
  .owlStyle .owl-nav .owl-prev, 
  .owlStyle .owl-nav .owl-next{
    padding: 24px 8px;
  }
}


@media screen and (max-width: 1023px){
  .filter{
    &-left{
      display: none;
    }
    &-right{
      margin-left: 0;
      .filter__link_sphere{
        display: flex;
      }
    } 
  }
  .form{
    margin-top: 80px;
    margin-bottom: 80px;
    &-img{
      display: none;
    }
    &-col .form-inputs .form-input_half{
      width: calc(50% - 12px);
    }
  }
  .firstSolo{
    margin-top: 74px !important;
    margin-bottom: 80px !important;
  }
  .breadcrumbs{
    padding-top: 16px;
  }
}


@media screen and (max-width: 767px){
  .filter-right .filter__link_map:after{
    content: 'На карте';
  }
  .filter-right .filter__link_list:after{
    content: 'Списком';
  }
  .filter-right{
    width: 100%;
  }
  .filter-right .filter__link{
    flex: 1;
  }
  .container{
    padding-right: 16px;
    padding-left: 16px;
  }
  .defaultTitle{
    font-size: 24px;
    line-height: 125%;
  }

  .form{
    &-row{
      margin-top: 40px;
    }
    &-col {
      .form-bottom{
        margin-top: 40px;
        display: block;
        .btn{
          margin-right: 0;
          width: 100%;
        }
        &__text{
          text-align: center;
          margin-top: 16px;
        }
      }
      .form-inputs{
        .form-input{
          margin-top: 32px;
          &_half{
            width: 100%;
          }
        }
      }
    }
  }
  .firstSolo{
    margin-top: 72px !important;
  }
  .breadcrumbs{
    margin-bottom: 24px;
  }
  .pagination__arr_next{
    margin-left: 8px;
  }
  .pagination__arr_prev{
    margin-right: 8px;
  }
  .filter-button_where:after{
    content: 'По месту';
  }
  .main__title{
    font-size: 24px;
    line-height: 125%;
    margin-bottom: 24px;
  }

  .popup{
    &-content{
      padding: 40px 16px;
      max-height: calc(100vh - 40px);
      overflow: auto;
    }
    &-filter{
      display: block;
      padding-top: 40px;
      width: 100%;
      margin: 0;
      &-input{
        width: 100%;
        margin: 0;
        &:not(:last-child){
          margin-bottom: 24px;
        }
      }
      &-bot{
        display: block;
        margin: 0;
        width: 100%;
        &__var{
          display: flex;
          margin-bottom: 24px;
          align-items: center;
          span{
            margin-left: auto;
          }
        }
      }
    }
  }
}