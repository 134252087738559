.article{
  &-banner{
    height: 416px;
    width: 100%;
    margin-bottom: 16px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-content{
    margin-top: 0;
    max-width: 866px;
    margin: 0 auto;
    time{
      font-size: 16px;
      line-height: 150%;
      display: block;
      color: var(--middleGray);
      margin-bottom: 40px;
    }
    p{
      font-size: 16px;
      line-height: 150%;
      color: #6B6B6B;
      &:not(:last-child){
        margin-bottom: 16px;
      }
    }
    .about-full-aside-galery{
      margin: 40px 0;
    }
    a{
      font-size: 16px;
      line-height: 150%;
      color: var(--purple);
      text-decoration-line: underline;
    }
  }
}


@media screen and (max-width: 1023px){
  .article-banner{
    height: 220px;
  }
  .article{
    .about-full-aside-galery__img{
      height: 270px;
    }
  }
}

@media screen and (max-width: 767px){
  .article{
    .about-full-aside-galery__img{
      height: 320px;
    }
  }
}