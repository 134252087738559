.news-grid{
  display: flex;
  margin: -20px;
  width: calc(100% + 40px);
  flex-wrap: wrap;
  margin-bottom: 60px;
}
.news-card{
  margin: 12px;
  padding: 8px;
  width: calc(100% / 3 - 24px);
  cursor: pointer;
  transition: .3s;
  border-radius: 3px;
  &:hover{
    @media screen and (min-width: 1024px) {
      background-color: var(--lightBlue);
    }
  }
  &-img{
    height: 280px;
    border-radius: 3px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &-desc{
    
    &__title{
      margin-top: 16px;
      font-weight: 500;
      color: var(--black);
      font-size: 16px;
      
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      -ms-line-clamp: 2;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      display: -webkit-box;
      display: box;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
      box-orient: vertical;
    }
    &__when{
      margin-top: 16px;
      font-size: 16px;
      line-height: 150%;
      color: var(--middleGray);
    }
  }
}


@media screen and (max-width: 1023px){
  .news-grid{
    margin: -12px -12px 68px;
    width: calc(100% + 24px);

  }
  .news-card{
    width: calc(50% - 8px);
    margin: 4px;
  }
}

@media screen and (max-width: 767px){
  .news{
    &-grid{
      width: 100%;
      margin: 0 0 40px;
    }
    &-card{
      margin: 0;
      width: 100%;
      padding: 0;
      &:not(:last-child){
        margin-bottom: 40px;
      }
    }
  }
}